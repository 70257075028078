import cx from 'classnames'
import { useMemo } from 'react'

import {
  type SanityGridColumn,
  type SanityGridModule,
} from '@data/sanity/queries/types/modules'
import { gridColorClasses } from '@lib/color'
import { getGridSizeClass, getItemOrderClass } from '@lib/dom'
import {
  getDefaultVerticalSpacingClasses,
  getSpacingClasses,
  getSpacingValuesFromGridColumnSizes,
} from '@lib/spacing'

import GridColumn from './grid-column'

type GridColumnProps = SanityGridColumn & {
  classes: (string | Record<string, boolean>)[]
}

type GridProps = Pick<
  SanityGridModule,
  | 'size'
  | 'spacing'
  | 'columns'
  | 'reverseSequence'
  | 'background'
  | 'noColumnGaps'
  | 'noRowGaps'
>

const Grid = ({
  size,
  spacing,
  columns: rawColumns,
  reverseSequence,
  background,
  noColumnGaps,
  noRowGaps,
}: GridProps) => {
  const columns = useMemo(
    () =>
      rawColumns?.map<GridColumnProps>((column, index) => {
        if (!column.sizes) {
          return {
            ...column,
            classes: [],
          } satisfies GridColumnProps
        }

        const columnClasses = column.sizes.map(
          ({ breakpoint, width, justify, align, start }) =>
            getGridSizeClass(breakpoint, width, justify, align, start),
        )
        const orderClasses = column.sizes.map(({ breakpoint, width }) =>
          getItemOrderClass(index, breakpoint, width, size, reverseSequence),
        )
        const spacingValues = getSpacingValuesFromGridColumnSizes(column.sizes)

        return {
          ...column,
          classes: [
            ...columnClasses,
            ...orderClasses,
            getSpacingClasses(spacingValues),
          ],
        } satisfies GridColumnProps
      }),
    [rawColumns, reverseSequence, size],
  )

  const sectionClasses = useMemo(
    () => [
      background ? gridColorClasses[background] : null,
      ...getDefaultVerticalSpacingClasses(spacing?.padding),
      getSpacingClasses(spacing?.padding, 'right'),
      getSpacingClasses(spacing?.padding, 'left'),
    ],
    [background, spacing],
  )

  return (
    <section className={cx(sectionClasses)}>
      <div className="relative container">
        <div className="mx-auto">
          <div
            className={cx(`grid grid-cols-${size}`, {
              'gap-x-4 sm:gap-x-8 lg:gap-x-12': !noColumnGaps,
              'gap-y-8 lg:gap-y-12': !noRowGaps,
              'gap-x-0': !!noColumnGaps,
              'gap-y-0': !!noRowGaps,
            })}
          >
            {columns?.map(({ _key, blocks, classes }) => (
              <GridColumn key={_key} blocks={blocks} className={cx(classes)} />
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Grid
