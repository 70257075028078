import { type SanityColorValue } from '@data/sanity/queries/types/color'

export const gridColorClasses: Record<SanityColorValue, string | string[]> = {
  black: 'bg-black text-white',
  white: 'bg-white text-black',
}

export const imageAndTextColorClasses: Record<
  SanityColorValue,
  string | string[]
> = {
  black: 'bg-black text-white',
  white: 'bg-white text-black',
}
