import axios, { type AxiosResponse } from 'axios'
import { sha256 } from 'js-sha256'

import {
  bookDemoEventName,
  contactEventName,
  newsletterSignUpEventName,
  triggerGoogleTagManagerEvent,
} from './analytics'
import { type Locale } from './language'

type Service = 'clickup' | 'hubSpot' | 'klaviyo'

export interface ClickUpContactPayload {
  listId: string
  firstName: string
  lastName: string
  email: string
  phone: string
  company: string
  message?: string
  emailCustomFieldName?: string
  phoneCustomFieldName?: string
  companyCustomFieldName?: string
}

export interface ClickUpContactResponse {
  error?: string
}

export interface HubSpotBookADemoPayload {
  formId: string
  consent: boolean
  email: string
  phoneNumber: string
  firstName: string
  lastName: string
  company?: string
}

export interface HubSpotBookADemoResponse {
  error?: string
}

export interface HubSpotNewsletterJoinPayload {
  formId: string
  consent: boolean
  email: string
  name?: string
}

export interface HubSpotNewsletterJoinResponse {
  error?: string
}

export interface HubSpotWaitlistJoinPayload {
  variantId: number
  email: string
  name?: string
}

export interface HubSpotWaitlistJoinResponse {
  error?: string
}

export interface KlaviyoBookADemoPayload {
  listId: string
  email: string
  phoneNumber: string
  firstName: string
  lastName: string
  company?: string
}

export interface KlaviyoBookADemoResponse {
  error?: string
}

export interface KlaviyoNewsletterJoinPayload {
  listId: string
  name?: string
  email: string
}

export interface KlaviyoNewsletterJoinResponse {
  error?: string
}

export interface KlaviyoWaitlistJoinPayload {
  variantId: number
  email: string
  name?: string
}

export interface KlaviyoWaitlistJoinResponse {
  error?: string
}

type BookADemoResponse = KlaviyoBookADemoResponse | HubSpotBookADemoResponse

type ContactResponse = ClickUpContactResponse

type NewsletterJoinResponse =
  | KlaviyoNewsletterJoinResponse
  | HubSpotNewsletterJoinResponse

type WaitlistJoinResponse =
  | KlaviyoWaitlistJoinResponse
  | HubSpotWaitlistJoinResponse

const getApiUrl = (service: string, action: string) =>
  `/api/${service.toLowerCase()}/${action}`

const getApiHeaders = (locale: Locale): Record<string, any> => ({
  'Content-Type': 'application/json',
  'X-Locale': locale,
})

const callApi = async <T>(
  url: string,
  payload: any,
  headers: Record<string, any>,
) =>
  await axios.post<T, AxiosResponse<T>, string>(url, JSON.stringify(payload), {
    headers,
  })

/**
 * Calls newsletter signup API page.
 */
export const addEmailToNewsletterList = async (
  locale: Locale,
  service: Service,
  newsletterTargetId: string,
  hasAcceptedTerms: boolean,
  email: string,
  name?: string,
) => {
  const url = getApiUrl(service, 'newsletter-join')
  const payload =
    service === 'klaviyo'
      ? ({
          listId: newsletterTargetId,
          email,
          name,
        } satisfies KlaviyoNewsletterJoinPayload)
      : service === 'hubSpot'
        ? ({
            formId: newsletterTargetId,
            consent: !!hasAcceptedTerms,
            email,
            name,
          } satisfies HubSpotNewsletterJoinPayload)
        : null
  const headers = getApiHeaders(locale)
  await callApi<NewsletterJoinResponse>(url, payload, headers)

  triggerGoogleTagManagerEvent(newsletterSignUpEventName)
}

/**
 * Calls waitlist signup API page.
 */
export const addEmailToWaitlist = async (
  locale: Locale,
  service: Service,
  variantId: number,
  email: string,
  name?: string,
) => {
  const url = getApiUrl(service, 'waitlist-join')
  const payload =
    service === 'klaviyo'
      ? ({
          variantId,
          email,
          name,
        } satisfies KlaviyoWaitlistJoinPayload)
      : null
  const headers = getApiHeaders(locale)
  await callApi<WaitlistJoinResponse>(url, payload, headers)
}

/**
 * Calls demo booking API page.
 */
export const bookADemo = async (
  locale: Locale,
  service: Service,
  bookingTargetId: string,
  hasAcceptedTerms: boolean,
  email: string,
  phoneNumber: string,
  firstName: string,
  lastName: string,
  company?: string,
  submitEventName?: string,
) => {
  const url = getApiUrl(service, 'book-a-demo')
  const payload =
    service === 'klaviyo'
      ? ({
          listId: bookingTargetId,
          email,
          phoneNumber,
          firstName,
          lastName,
          company,
        } satisfies KlaviyoBookADemoPayload)
      : service === 'hubSpot'
        ? ({
            formId: bookingTargetId,
            consent: !!hasAcceptedTerms,
            email,
            phoneNumber,
            firstName,
            lastName,
            company,
          } satisfies HubSpotBookADemoPayload)
        : null
  const headers = getApiHeaders(locale)
  await callApi<BookADemoResponse>(url, payload, headers)

  triggerGoogleTagManagerEvent(submitEventName ?? bookDemoEventName, {
    user_data: {
      sha256_email_address: sha256(email),
      phone_number: phoneNumber,
      address: {
        first_name: firstName,
        last_name: lastName,
      },
      companyName: company,
    },
  })
}

/**
 * Calls contact API page.
 */
export const contact = async (
  locale: Locale,
  service: Service,
  contactTargetId: string,
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  company: string,
  message?: string,
  emailCustomFieldName?: string,
  phoneCustomFieldName?: string,
  companyCustomFieldName?: string,
  submitEventName?: string,
) => {
  const url = getApiUrl(service, 'contact')
  const payload =
    service === 'clickup'
      ? ({
          listId: contactTargetId,
          firstName,
          lastName,
          email,
          phone,
          company,
          message,
          emailCustomFieldName,
          phoneCustomFieldName,
          companyCustomFieldName,
        } satisfies ClickUpContactPayload)
      : null
  const headers = getApiHeaders(locale)
  await callApi<ContactResponse>(url, payload, headers)

  triggerGoogleTagManagerEvent(submitEventName ?? contactEventName)
}
