import { type SanityImageFragment } from '@data/sanity/queries/types/image'

import Photo from '@components/photo'

interface BundleSlotHeaderProps {
  slotTitle: string
  selectedVariantTitle?: string
  selectedVariantPhoto?: SanityImageFragment | null
}

const BundleSlotHeader = ({
  slotTitle,
  selectedVariantTitle,
  selectedVariantPhoto,
}: BundleSlotHeaderProps) => {
  return (
    <div className="flex items-center justify-between mb-8 min-h-[75px]">
      <div>
        {!!selectedVariantTitle && (
          <p className="text-sm leading-relaxed tracking-wider font-semibold uppercase">
            {selectedVariantTitle}
          </p>
        )}
        <h3 className="font-semibold mb-0 text-2xl">{slotTitle}</h3>
      </div>

      {!!selectedVariantPhoto && (
        <Photo
          image={selectedVariantPhoto}
          width={75}
          height={75}
          className="rounded-full overflow-hidden w-[75px] h-[75px]"
          imageClassName="object-cover w-[75px] h-[75px]"
        />
      )}
    </div>
  )
}

export default BundleSlotHeader
