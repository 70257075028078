import { useRouter } from 'next/router'
import { type BaseSyntheticEvent, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'

import { type SanityLoginFormStrings } from '@data/sanity/queries/types/blocks'
import { ParseStatus } from '@lib/shopify/graphql/client'
import { type LoginFormValues, useLoginUser } from '@lib/auth'
import { type ErrorMessages } from '@lib/helpers'
import { LanguageContext } from '@lib/language-context'
import { getLinkPageUrl } from '@lib/routes'
import { StringsContext } from '@lib/strings-context'

import Alert from '@components/alert'
import Button, { ButtonVariant } from '@components/buttons/button'
import InputField from '@components/input-field'
import ComplexPortableText from '@components/complex-portable-text'

interface LoginFormProps {
  className?: string
  loginFormStrings: SanityLoginFormStrings
}

const LoginForm = ({ loginFormStrings, className }: LoginFormProps) => {
  const { locale } = useContext(LanguageContext)

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<LoginFormValues>()
  const [isError, setIsError] = useState(false)
  const [errorStatus, setErrorStatus] = useState<ParseStatus>()
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessages, setErrorMessages] = useState<ErrorMessages>({})

  const strings = useContext(StringsContext)
  const router = useRouter()
  const loginUser = useLoginUser()

  // Handle form submission
  const onSubmit = async (
    values: LoginFormValues,
    event?: BaseSyntheticEvent,
  ) => {
    event?.preventDefault()

    setIsLoading(true)
    setIsError(false)

    // Login
    const loginUserResult = await loginUser(values)
    setErrorMessages(loginUserResult.fieldErrors)

    if (loginUserResult.status !== ParseStatus.OK) {
      setErrorStatus(loginUserResult.status)
      setIsError(true)
    }

    if (
      loginUserResult.status === ParseStatus.OK &&
      loginUserResult.errorCount === 0
    ) {
      reset()

      // Redirect to account after logging in
      const url = getLinkPageUrl('accountPage')
      router.push(url, url, {
        locale,
      })
    }

    setIsLoading(false)
  }

  const emailRegister = register('email', {
    required: loginFormStrings.signupEmailMissing,
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: strings.emailInvalid,
    },
  })

  const passwordRegister = register('password', {
    required: loginFormStrings.signupPasswordMissing,
  })

  const isDisabled = !!errors.email || !!errors.password

  return (
    <form className={className} onSubmit={handleSubmit(onSubmit)}>
      <div key="form">
        <InputField
          id="login-email"
          type="email"
          autoComplete="email"
          formRegister={emailRegister}
          errorMessage={errorMessages?.email ?? errors.email?.message}
          label={loginFormStrings.signupEmail}
          placeholder={loginFormStrings.signupEmailPlaceholder}
          className="mb-4"
        />

        <InputField
          id="login-password"
          type="password"
          autoComplete="off"
          formRegister={passwordRegister}
          errorMessage={errorMessages?.password ?? errors.password?.message}
          label={loginFormStrings.signupPassword}
          placeholder={loginFormStrings.signupPasswordPlaceholder}
          className="mb-4"
        />

        {isError && errorStatus && (
          <div key="error" className="mt-8">
            <Alert error className="rc rc-alert rc-error">
              {errorStatus === ParseStatus.INVALID_CREDENTIALS && (
                <ComplexPortableText
                  content={loginFormStrings.loginFailedMessage}
                />
              )}
              {errorStatus !== ParseStatus.INVALID_CREDENTIALS && (
                <ComplexPortableText
                  content={loginFormStrings.loginErrorMessage}
                />
              )}
            </Alert>
          </div>
        )}

        <div className="flex justify-center mt-8">
          <Button
            type="submit"
            variant={ButtonVariant.FILLED}
            disabled={isLoading || isDisabled}
            className="min-w-[260px]"
          >
            {isLoading
              ? strings.buttonSubmitting
              : loginFormStrings.loginSubmit}
          </Button>
        </div>
      </div>
    </form>
  )
}

export default LoginForm
