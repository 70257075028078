import {
  type SanityContentCarouselBlock,
  type SanityContentCarouselItem,
} from '@data/sanity/queries/types/blocks'

import Carousel from '@components/carousel'
import ProductCard from '@blocks/shop/product-card'
import BlogPostCard from '@blocks/blog/blog-post-card'
import Freeform from '@blocks/freeform'
import Photo from '@components/photo'
import VimeoVideo from '@components/video/vimeo-video'
import MuxVideo from '@components/video/mux-video'

interface ContentCarouselItemProps {
  item: SanityContentCarouselItem
}

type ContentCarouselProps = Pick<SanityContentCarouselBlock, 'items'>

const ContentCarouselItem = ({ item }: ContentCarouselItemProps) => {
  switch (item._type) {
    case 'blogPost': {
      const { blogPost } = item

      return (
        <BlogPostCard
          post={blogPost}
          options={{
            showDate: true,
          }}
        />
      )
    }

    case 'freeform': {
      const { content, textAlign, maxWidth } = item

      return (
        <Freeform content={content} textAlign={textAlign} maxWidth={maxWidth} />
      )
    }

    case 'photo': {
      const image = item

      return (
        <Photo
          image={{
            ...image,
            _type: 'figure',
          }}
        />
      )
    }

    case 'product': {
      const { product } = item
      const {
        product: { useGallery, galleryPhotos, listingPhotos, surfaceOption },
      } = item

      return (
        <ProductCard
          product={product}
          hasVisuals={!!galleryPhotos || !!listingPhotos}
          showGallery={useGallery === 'true' && !!galleryPhotos}
          showThumbnails={useGallery === 'false' && !!listingPhotos}
          showOption={!!surfaceOption}
          showPrice
        />
      )
    }

    case 'video': {
      const {
        type,
        muxVideo,
        settings,
        aspectRatio,
        vimeoVideo,
        borderRadius,
      } = item

      if (type === 'mux' && !!muxVideo) {
        return (
          <MuxVideo
            video={muxVideo}
            showControls={settings?.controls}
            autoplay={settings?.autoplay}
            loop={settings?.loop}
            muted={settings?.muted}
            borderRadius={borderRadius}
            customAspectRatio={aspectRatio}
          />
        )
      }

      if (type === 'vimeo' && !!vimeoVideo) {
        return <VimeoVideo video={vimeoVideo} borderRadius={borderRadius} />
      }

      return null
    }
  }
}

const ContentCarousel = ({ items }: ContentCarouselProps) => {
  return (
    <Carousel
      options={{
        selector: '.content-slide',
      }}
    >
      {items?.map((item, index) => (
        <div className="content-slide" key={index}>
          <ContentCarouselItem item={item} />
        </div>
      ))}
    </Carousel>
  )
}

export default ContentCarousel
