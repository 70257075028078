import cx from 'classnames'
import { Fragment, useContext } from 'react'

import { type SanityAccountDetailsStrings } from '@data/sanity/queries/types/blocks'
import { getShopifySubscriptionManagementUrl } from '@lib/shopify/customer'
import { useUser } from '@lib/auth'
import { getLinkPageUrl } from '@lib/routes'
import { ShopContext } from '@lib/shop-context'

import { ButtonIconSize, ButtonIconAlignment } from '@components/buttons/button'
import ButtonLink from '@components/buttons/button-link'

interface AccountDetailsProps {
  className?: string
  accountDetailsStrings: SanityAccountDetailsStrings
}

const AccountDetails = ({
  accountDetailsStrings,
  className,
}: AccountDetailsProps) => {
  const { shopifyPrimaryDomain } = useContext(ShopContext)
  const { user } = useUser()

  const addressCount = user?.addresses?.length ?? 0
  const defaultAddress =
    user?.addresses?.find((address) => address.isDefault) ??
    user?.addresses?.[0]
  const subscriptionManagementUrl =
    shopifyPrimaryDomain && user?.subscriberHash
      ? getShopifySubscriptionManagementUrl(
          shopifyPrimaryDomain,
          user.subscriberHash,
        )
      : null

  return (
    <div
      className={cx(className, 'bg-box-bg border border-box-border p-6 lg:p-8')}
    >
      <h4>{accountDetailsStrings.accountAccountDetailsHeading}</h4>

      {defaultAddress && (
        <div className="mt-5 text-sm leading-normal">
          {defaultAddress.formatted.map((addressLine, index) => (
            <Fragment key={index}>
              {addressLine}
              {defaultAddress.formatted[index + 1] && <br />}
            </Fragment>
          ))}
        </div>
      )}

      <ButtonLink
        href={getLinkPageUrl('accountAddressPage')}
        className="w-full mt-5 text-sm font-medium"
        icon="ArrowRight"
        iconSize={ButtonIconSize.NORMAL}
        iconAlignment={ButtonIconAlignment.LEFT}
      >
        {accountDetailsStrings.accountViewAddresses} ({addressCount})
      </ButtonLink>

      {subscriptionManagementUrl && (
        <ButtonLink
          href={subscriptionManagementUrl}
          target="_blank"
          className="w-full mt-5 text-sm font-medium"
          icon="ArrowRight"
          iconAlignment={ButtonIconAlignment.LEFT}
        >
          {accountDetailsStrings.accountViewSubscriptions}
        </ButtonLink>
      )}
    </div>
  )
}

export default AccountDetails
