import { type SanityCollectionCarouselBlock } from '@data/sanity/queries/types/blocks'

import ProductCard from '@blocks/shop/product-card'
import Carousel from '@components/carousel'

type CollectionCarouselProps = Pick<SanityCollectionCarouselBlock, 'collection'>

const CollectionCarousel = ({ collection }: CollectionCarouselProps) => {
  return (
    <Carousel
      options={{
        selector: '.collection-slide',
      }}
    >
      {collection.products.map((product, index) => {
        const {
          galleryPhotos,
          listingPhotos,
          productID,
          surfaceOption,
          useGallery,
        } = product

        return (
          <div className="collection-slide" key={`${index}-${productID}`}>
            <ProductCard
              product={product}
              hasVisuals={!!galleryPhotos || !!listingPhotos}
              showGallery={useGallery === 'true' && !!galleryPhotos}
              showThumbnails={useGallery === 'false' && !!listingPhotos}
              showOption={!!surfaceOption}
              showPrice
            />
          </div>
        )
      })}
    </Carousel>
  )
}

export default CollectionCarousel
