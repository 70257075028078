import { useContext, useEffect, useState } from 'react'

import { type SanityAccountProductListStrings } from '@data/sanity/queries/types/blocks'
import { getPageUrl } from '@lib/routes'
import { LanguageContext } from '@lib/language-context'
import { StringsContext } from '@lib/strings-context'
import { useUserProductList } from '@lib/user'

import Button, { ButtonSize, ButtonVariant } from '@components/buttons/button'
import ComplexPortableText from '@components/complex-portable-text'

interface AccountProductListProps {
  className?: string
  accountProductListStrings: SanityAccountProductListStrings
}

const AccountProductList = ({
  accountProductListStrings,
  className,
}: AccountProductListProps) => {
  const strings = useContext(StringsContext)
  const { locale } = useContext(LanguageContext)

  const [productPageIndex, setProductPageIndex] = useState(1)
  const [hasMoreProducts, setHasMoreProducts] = useState(false)
  const {
    isLoadingProducts,
    products: allProducts,
    productsPerPage,
  } = useUserProductList()

  const products = allProducts.slice(0, productPageIndex * productsPerPage)
  const productCount = allProducts.length

  useEffect(() => {
    setHasMoreProducts(productPageIndex * productsPerPage < productCount)
  }, [productCount, productPageIndex, productsPerPage])

  return (
    <div className={className}>
      <h4>{accountProductListStrings.accountProductListHeading}</h4>

      {products.length === 0 && !isLoadingProducts && (
        <div className="rc mt-3">
          <ComplexPortableText
            content={accountProductListStrings.accountProductListEmpty}
          />
        </div>
      )}

      {products.length > 0 && (
        <div className="mt-4">
          <div className="py-4 px-5 text-pageText text-sm hidden sm:flex">
            <div className="w-full">
              {accountProductListStrings.accountProductListProduct}
            </div>
          </div>

          {products.map((product) => (
            <div
              key={product.id}
              className="flex flex-col sm:flex-row py-5 px-5 bg-box-bg border-t border-box-border border-opacity-25 text-sm"
            >
              <div className="mb-4 sm:mb-0 sm:w-[80%] sm:pr-4">
                {product.title}
              </div>
              <div className="sm:w-[20%] text-right text-pageText">
                <a href={getPageUrl(locale, 'product', product.slug)}>
                  {accountProductListStrings.accountProductListViewProduct}
                </a>
              </div>
            </div>
          ))}

          {hasMoreProducts && (
            <div className="mt-4 text-center">
              <Button
                variant={ButtonVariant.OUTLINED}
                size={ButtonSize.SMALL}
                disabled={isLoadingProducts}
                className="min-w-[136px]"
                onClick={() => setProductPageIndex((index) => index + 1)}
              >
                {isLoadingProducts
                  ? strings.buttonLoading
                  : strings.buttonLoadMore}
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default AccountProductList
