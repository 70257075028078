import cx from 'classnames'

import { type SanityBlock } from '@data/sanity/queries/types/blocks'

import Accordions from '@blocks/accordions'
import AccountAddressDetails from '@blocks/account/account-address-details'
import AccountDetails from '@blocks/account/account-details'
import AccountOrderList from '@blocks/account/account-order-list'
import AccountProductList from '@blocks/account/account-product-list'
import BlogPostCard from '@blocks/blog/blog-post-card'
import CalendlyWidget from '@blocks/calendly-widget'
import CollectionCarousel from '@blocks/shop/collection-carousel'
import ContactForm from '@blocks/contact-form'
import ContentCarousel from '@blocks/content-carousel'
import CookieDeclaration from '@blocks/cookiebot/cookie-declaration'
import DemoForm from '@blocks/demo-form'
import Freeform from '@blocks/freeform'
import LoginForm from '@blocks/account/login-form'
import Newsletter from '@blocks/newsletter'
import PasswordRecoveryForm from '@blocks/account/password-recovery-form'
import ProductBundleForm from '@blocks/shop/product-bundle-form'
import ProductCard from '@blocks/shop/product-card'
import SignupForm from '@blocks/account/signup-form'
import TeamMemberCard from '@blocks/team-member-card'
import VideoModule from './video'

interface GridBlockProps {
  block: SanityBlock
}

const GridBlock = ({ block }: GridBlockProps) => {
  switch (block._type) {
    case 'accordions': {
      const { items } = block

      return <Accordions items={items} />
    }

    case 'accountAddressDetails': {
      const { active, accountAddressDetailsStrings, addressFormStrings } = block

      if (!active) {
        return null
      }

      return (
        <AccountAddressDetails
          accountAddressDetailsStrings={accountAddressDetailsStrings}
          addressFormStrings={addressFormStrings}
          className="mt-10 mb-10"
        />
      )
    }

    case 'accountDetails': {
      const { active, accountDetailsStrings } = block

      if (!active) {
        return null
      }

      return (
        <AccountDetails
          accountDetailsStrings={accountDetailsStrings}
          className="mb-10"
        />
      )
    }

    case 'accountOrderList': {
      const { active, accountOrderListStrings } = block

      if (!active) {
        return null
      }

      return (
        <AccountOrderList
          accountOrderListStrings={accountOrderListStrings}
          className="mb-16"
        />
      )
    }

    case 'accountProductList': {
      const { active, accountProductListStrings } = block

      if (!active) {
        return null
      }

      return (
        <AccountProductList
          accountProductListStrings={accountProductListStrings}
          className="mb-16"
        />
      )
    }

    case 'blogPostCard': {
      const { post, options } = block

      return <BlogPostCard post={post} options={options} />
    }

    case 'calendlyWidget': {
      const { url, hideEventDetails, hideCookieSettings } = block

      return (
        <CalendlyWidget
          url={url}
          hideEventDetails={hideEventDetails}
          hideCookieSettings={hideCookieSettings}
        />
      )
    }

    case 'collectionCarousel': {
      const { collection } = block

      return <CollectionCarousel collection={collection} />
    }

    case 'contactForm': {
      const {
        _key,
        service,
        clickUpListId,
        dynamicParameters,
        submit,
        analytics,
        successMsg,
        errorMsg,
        terms,
        buttonStyle,
      } = block

      return (
        <ContactForm
          id={_key}
          service={service}
          clickUpListId={clickUpListId}
          dynamicParameters={dynamicParameters}
          submit={submit}
          analytics={analytics}
          successMsg={successMsg}
          errorMsg={errorMsg}
          terms={terms}
          buttonStyle={buttonStyle}
        />
      )
    }

    case 'contentCarousel': {
      const { items } = block

      return <ContentCarousel items={items} />
    }

    case 'cookieDeclaration': {
      const { active, cookieBotId } = block

      if (!active || !cookieBotId) {
        return null
      }

      return <CookieDeclaration cookieBotId={cookieBotId} />
    }

    case 'demoForm': {
      const {
        _key,
        service,
        hubSpotFormId,
        hubSpotNewsletterFormId,
        klaviyoListID,
        klaviyoNewsletterListID,
        submit,
        analytics,
        successMsg,
        errorMsg,
        terms,
        newsletterStatement,
        buttonStyle,
        strings,
      } = block

      return (
        <DemoForm
          id={_key}
          service={service}
          hubSpotFormId={hubSpotFormId}
          hubSpotNewsletterFormId={hubSpotNewsletterFormId}
          klaviyoListID={klaviyoListID}
          klaviyoNewsletterListID={klaviyoNewsletterListID}
          submit={submit}
          analytics={analytics}
          successMsg={successMsg}
          errorMsg={errorMsg}
          terms={terms}
          newsletterStatement={newsletterStatement}
          buttonStyle={buttonStyle}
          strings={strings}
        />
      )
    }

    case 'freeform': {
      const { content, textAlign, maxWidth } = block

      return (
        <Freeform content={content} textAlign={textAlign} maxWidth={maxWidth} />
      )
    }

    case 'iframe': {
      const { title, url, width, height } = block

      return (
        <iframe
          title={title}
          src={url}
          className={cx({ 'w-full': !width })}
          width={width}
          height={height}
        />
      )
    }

    case 'loginForm': {
      const { active, loginFormStrings } = block

      if (!active) {
        return null
      }

      return (
        <LoginForm
          loginFormStrings={loginFormStrings}
          className="mt-16 mb-10"
        />
      )
    }

    case 'newsletter': {
      const {
        _key,
        title,
        description,
        variant,
        service,
        hubSpotFormId,
        klaviyoListID,
        submit,
        successMsg,
        errorMsg,
        terms,
      } = block

      return (
        <Newsletter
          id={_key}
          title={title}
          description={description}
          variant={variant}
          service={service}
          hubSpotFormId={hubSpotFormId}
          klaviyoListID={klaviyoListID}
          submit={submit}
          successMsg={successMsg}
          errorMsg={errorMsg}
          terms={terms}
        />
      )
    }

    case 'passwordRecoveryForm': {
      const { active, passwordRecoveryFormStrings } = block

      if (!active) {
        return null
      }

      return (
        <PasswordRecoveryForm
          passwordRecoveryFormStrings={passwordRecoveryFormStrings}
          className="mt-16 mb-10"
        />
      )
    }

    case 'predefinedContentBlock': {
      const { contentBlocks } = block

      return (
        <>
          {contentBlocks?.map((contentBlock) => (
            <GridBlock key={contentBlock._key} block={contentBlock} />
          ))}
        </>
      )
    }

    case 'productBundleForm': {
      const { productBundle, showGallery } = block

      return (
        <ProductBundleForm
          productBundle={productBundle}
          showGallery={showGallery}
        />
      )
    }

    case 'productCard': {
      const { product } = block

      return (
        <ProductCard
          product={product}
          hasVisuals
          showThumbnails
          showOption
          showPrice
          isInline
        />
      )
    }

    case 'signupForm': {
      const { active, signupFormStrings } = block

      if (!active) {
        return null
      }

      return (
        <SignupForm
          signupFormStrings={signupFormStrings}
          className="mt-16 mb-10"
        />
      )
    }

    case 'teamMemberCard': {
      const { teamMember } = block

      return <TeamMemberCard teamMember={teamMember} />
    }

    case 'video': {
      const {
        type,
        vimeoVideo,
        muxVideo,
        settings,
        aspectRatio,
        borderRadius,
      } = block

      return (
        <VideoModule
          type={type}
          vimeoVideo={vimeoVideo}
          muxVideo={muxVideo}
          settings={settings}
          aspectRatio={aspectRatio}
          borderRadius={borderRadius}
        />
      )
    }
  }
}

export default GridBlock
