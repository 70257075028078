import { useContext } from 'react'

import { CartContext } from '@lib/cart/context'
import { StringsContext } from '@lib/strings-context'

import Alert from '@components/alert'
import Button, { ButtonVariant } from '@components/buttons/button'
import SimplePortableText from '@components/simple-portable-text'

interface BundleAddToCartButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>
  isAddToCartError: boolean
}

const BundleAddToCartButton = ({
  onClick,
  isAddToCartError,
}: BundleAddToCartButtonProps) => {
  const { cart, isCartProductAdding } = useContext(CartContext)
  const strings = useContext(StringsContext)

  return (
    <>
      <Button
        id="bundle-add-to-cart-button"
        variant={ButtonVariant.FILLED}
        onClick={onClick}
        disabled={!cart || isCartProductAdding || isAddToCartError}
      >
        {isCartProductAdding && strings.buttonAdding}
        {cart &&
          !isCartProductAdding &&
          !isAddToCartError &&
          strings.buttonAddToCart}
      </Button>

      {isAddToCartError && (
        <div key="error" className="mt-6">
          <Alert error className="rc rc-alert rc-error">
            <SimplePortableText content={strings.cartAddToCartErrorMessage} />
          </Alert>
        </div>
      )}
    </>
  )
}

export default BundleAddToCartButton
