import {
  type SanityProductFragment,
  type SanityProductVariantFragment,
} from '@data/sanity/queries/types/product'
import { getOptionLabel } from '@lib/product'

import ProductOption from '@blocks/product/product-option'

interface BundleSlotOptionsProps {
  product: SanityProductFragment
  variant: SanityProductVariantFragment | null
  availableOptions: Record<string, string[]>
  optionNames: string[]
  onChange(variant: SanityProductVariantFragment): void
}

const BundleSlotOptions = ({
  product,
  variant,
  optionNames,
  availableOptions,
  onChange,
}: BundleSlotOptionsProps) => {
  return (
    <div>
      {product.options.map((option, index) => {
        const filteredValues = [
          ...option.values.filter((value) =>
            availableOptions[option.name]?.some(
              (optionValue) => optionValue === value,
            ),
          ),
        ]

        if (
          filteredValues.length === 0 ||
          !variant ||
          !optionNames.includes(option.name)
        ) {
          return null
        }

        const filteredOption = {
          ...option,
          values: filteredValues,
        }

        return (
          <ProductOption
            key={option.name}
            label={getOptionLabel(product.optionNames ?? [], option)}
            position={index}
            option={filteredOption}
            optionSettings={product.optionSettings ?? []}
            variants={product.variants ?? []}
            activeVariant={variant}
            onChange={onChange}
          />
        )
      })}
    </div>
  )
}

export default BundleSlotOptions
