import { type SanityGridColumn } from '@data/sanity/queries/types/modules'

import GridBlock from './grid-block'

type GridColumnProps = Pick<SanityGridColumn, 'blocks'> & {
  className?: string
}

const GridColumn = ({ className, blocks }: GridColumnProps) => {
  return (
    <div className={className}>
      {blocks?.map((block) => <GridBlock key={block._key} block={block} />)}
    </div>
  )
}

export default GridColumn
