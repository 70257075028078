import cx from 'classnames'
import { motion, AnimatePresence } from 'framer-motion'
import {
  type BaseSyntheticEvent,
  type FormEvent,
  useContext,
  useState,
} from 'react'
import { useForm } from 'react-hook-form'

import { type SanityContactFormBlock } from '@data/sanity/queries/types/blocks'
import { fadeAnimation } from '@lib/animate'
import { FormValueProvider } from '@lib/form-value-context'
import { LanguageContext } from '@lib/language-context'
import { contact } from '@lib/services'
import { StringsContext } from '@lib/strings-context'

import Alert from '@components/alert'
import Button, {
  ButtonColor,
  ButtonSize,
  ButtonVariant,
  getButtonColor,
  getButtonIconAlignment,
  getButtonSize,
  getButtonVariant,
} from '@components/buttons/button'
import Checkbox from '@components/checkbox'
import ComplexPortableText from '@components/complex-portable-text'
import InputField from '@components/input-field'
import SimplePortableText from '@components/simple-portable-text'
import TelInputField from '@components/tel-input-field'
import TextArea from '@components/textarea'

type ContactFormProps = Omit<SanityContactFormBlock, '_key' | '_type'> & {
  id: string
  className?: string
}

interface ContactFormValues {
  email: string
  phone: string
  firstName: string
  lastName: string
  company: string
  message?: string
  acceptTerms?: boolean
}

const ContactForm = ({
  service,
  clickUpListId,
  dynamicParameters,
  analytics,
  terms,
  submit,
  successMsg,
  errorMsg,
  buttonStyle,
  id,
  className,
}: ContactFormProps) => {
  const contactTargetId = service === 'clickup' ? clickUpListId : null

  const strings = useContext(StringsContext)
  const { locale } = useContext(LanguageContext)

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [formValues, setFormValues] = useState<ContactFormValues>()

  const {
    handleSubmit,
    register,
    watch,
    reset,
    formState: { errors },
    setValue,
    trigger,
  } = useForm<ContactFormValues>()
  const hasAcceptedTerms = watch('acceptTerms')
  register('phone', {
    required: strings.phoneNumberMissing,
  })
  const firstNameRegister = register('firstName', {
    required: strings.firstNameMissing,
  })
  const lastNameRegister = register('lastName', {
    required: strings.lastNameMissing,
  })
  const emailRegister = register('email', {
    required: strings.emailMissing,
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: strings.emailInvalid,
    },
  })
  const companyRegister = register('company')
  const messageRegister = register('message')
  const acceptTermsRegister = register('acceptTerms')

  const resetForm = (event: FormEvent) => {
    event.preventDefault()

    reset()
    setIsError(false)
    setIsSuccess(false)
    setIsSubmitting(false)
  }

  const onSubmit = async (
    contactFormValues: ContactFormValues,
    event?: BaseSyntheticEvent,
  ) => {
    event?.preventDefault()

    setIsError(false)
    setIsSuccess(false)
    setFormValues(contactFormValues)

    // Show an error if there is no contact target
    if (!contactTargetId) {
      setIsError(true)
      return
    }

    // Stop if accepting of terms is required
    if (!hasAcceptedTerms && !!terms) {
      return
    }

    setIsSubmitting(true)

    try {
      await contact(
        locale,
        service,
        contactTargetId,
        contactFormValues.firstName,
        contactFormValues.lastName,
        contactFormValues.email,
        contactFormValues.phone,
        contactFormValues.company,
        contactFormValues.message,
        dynamicParameters?.find(
          (dynamicParameter) => dynamicParameter.source === 'contact-email',
        )?.clickUpCustomFieldName,
        dynamicParameters?.find(
          (dynamicParameter) => dynamicParameter.source === 'contact-phone',
        )?.clickUpCustomFieldName,
        dynamicParameters?.find(
          (dynamicParameter) => dynamicParameter.source === 'contact-company',
        )?.clickUpCustomFieldName,
        analytics?.submitEventName,
      )

      setIsSuccess(true)
    } catch (error) {
      console.log(error)
      setIsError(true)
    }

    setIsSubmitting(false)
  }

  if (!service) {
    return null
  }

  const isDisabled = isSubmitting || (!!terms && !hasAcceptedTerms)

  return (
    <FormValueProvider
      formValues={{
        'contact-firstName': formValues?.firstName,
        'contact-lastName': formValues?.lastName,
        'contact-email': formValues?.email,
        'contact-phone': formValues?.phone?.replace(/ /g, ''),
        'contact-company': formValues?.company,
        'contact-message': formValues?.message,
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)} className={cx(className)}>
        <AnimatePresence mode="wait">
          {!isError && !isSuccess && (
            <motion.div
              initial="hide"
              animate="show"
              exit="hide"
              variants={fadeAnimation}
            >
              <div className="flex flex-col sm:flex-row sm:gap-4">
                <InputField
                  id={`contact-${id}-firstName`}
                  type="text"
                  formRegister={firstNameRegister}
                  placeholder={strings.firstNamePlaceholder}
                  label={strings.firstName}
                  errorMessage={errors.firstName?.message}
                  className="sm:w-1/2 mb-4"
                />

                <InputField
                  id={`contact-${id}-lastName`}
                  type="text"
                  formRegister={lastNameRegister}
                  placeholder={strings.lastNamePlaceholder}
                  label={strings.lastName}
                  errorMessage={errors.lastName?.message}
                  className="sm:w-1/2 mb-4"
                />
              </div>

              <InputField
                id={`contact-${id}-email`}
                type="email"
                formRegister={emailRegister}
                placeholder={strings.emailAddressPlaceholder}
                label={strings.emailAddress}
                errorMessage={errors.email?.message}
                className="mb-4"
              />

              <TelInputField
                id={`contact-${id}-phone`}
                placeholder={strings.phoneNumberPlaceholder}
                label={strings.phoneNumber}
                errorMessage={errors.phone?.message}
                onChange={(value) => {
                  setValue('phone', value)
                  trigger('phone')
                }}
                className="mb-4"
              />

              <InputField
                id={`contact-${id}-company`}
                type="text"
                formRegister={companyRegister}
                placeholder={strings.companyPlaceholder}
                label={strings.company}
                errorMessage={errors.company?.message}
                className="mb-4"
              />

              <TextArea
                id={`contact-${id}-message`}
                formRegister={messageRegister}
                placeholder={strings.messagePlaceholder}
                label={strings.message}
                errorMessage={errors.message?.message}
                className="mb-4"
              />

              {terms && (
                <Checkbox
                  id={`contact-${id}-acceptTerms`}
                  formRegister={acceptTermsRegister}
                  className="mb-3"
                >
                  <div className="rc rc-checkbox">
                    <SimplePortableText content={terms} />
                  </div>
                </Checkbox>
              )}

              <div className="flex justify-center">
                <Button
                  type="submit"
                  variant={
                    buttonStyle?.variant
                      ? getButtonVariant(buttonStyle.variant)
                      : ButtonVariant.FILLED
                  }
                  size={
                    buttonStyle?.size
                      ? getButtonSize(buttonStyle.size)
                      : ButtonSize.NORMAL
                  }
                  color={
                    buttonStyle?.color
                      ? getButtonColor(buttonStyle.color)
                      : ButtonColor.DEFAULT
                  }
                  icon={buttonStyle?.icon}
                  iconAlignment={getButtonIconAlignment(
                    buttonStyle?.iconAlignment,
                  )}
                  disabled={isDisabled}
                  className={cx('mt-3', buttonStyle?.fontCase ?? '', {
                    'w-full': buttonStyle?.isFullWidth,
                  })}
                >
                  {submit}
                </Button>
              </div>
            </motion.div>
          )}

          {isSuccess && (
            <motion.div
              key="success"
              initial="hide"
              animate="show"
              exit="hide"
              variants={fadeAnimation}
            >
              <Alert className="rc rc-alert">
                <ComplexPortableText content={successMsg} />
              </Alert>
            </motion.div>
          )}

          {isError && (
            <motion.div
              key="error"
              initial="hide"
              animate="show"
              exit="hide"
              variants={fadeAnimation}
            >
              <Alert
                buttonText={strings.buttonTryAgain}
                onClick={resetForm}
                className="rc rc-alert"
              >
                <ComplexPortableText content={errorMsg} />
              </Alert>
            </motion.div>
          )}
        </AnimatePresence>
      </form>
    </FormValueProvider>
  )
}

export default ContactForm
