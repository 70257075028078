import { gql } from '@apollo/client'

import {
  mailingAddressFragment,
  orderFragment,
  orderProductFragment,
} from '../fragments'

export const getCustomerQuery = gql`
  ${mailingAddressFragment}

  query getCustomer($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      id
      defaultAddress {
        id
      }
      addresses(first: 25) {
        edges {
          node {
            ...MailingAddressFragment
          }
        }
      }
    }
  }
`

export const getCustomerOrdersQuery = gql`
  ${orderFragment}

  query getCustomerOrders(
    $customerAccessToken: String!
    $orderLimit: Int!
    $afterCursor: String
  ) {
    customer(customerAccessToken: $customerAccessToken) {
      id
      orders(first: $orderLimit, after: $afterCursor, reverse: true) {
        edges {
          node {
            ...OrderFragment
          }
          cursor
        }
        pageInfo {
          hasNextPage
        }
      }
    }
  }
`

export const getCustomerProductsQuery = gql`
  ${orderProductFragment}

  query getCustomerProducts($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      id
      orders(first: 100) {
        edges {
          node {
            ...OrderProductFragment
          }
        }
      }
    }
  }
`
